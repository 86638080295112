import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { RelatedPass } from './RelatedPass'
import './RelatedPosts.scss';

const RelatedPosts = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          categoryFilter: allWordpressPost {
            nodes {
              categories {
                wordpress_id
                id
                name
                slug
                id
              }
            }
          }
          allWordpressPost(
            filter: { slug: {ne: "gatsby-demo" }},
            sort: { fields: date, order: DESC },
            limit: 6
          ) {
            edges {
              node {
                ...PostListFields
              }
            }
          }
        }
      `}
      render={data => <RelatedPass {...props} posts={data.allWordpressPost} categoryFilter={data.categoryFilter} contentType="blog" />}
    />
  );
}

export default RelatedPosts
