import React from 'react'
import { graphql } from 'gatsby'
import GatsbyLink from '../components/GatsbyLink'
import Previewable from '../components/Previewable'
import SEO from '../components/SEO'
import { PageHeader } from '../components/PageHeader'
import { PostRelatedCategories } from '../components/Posts/PostRelatedCategories'
import RelatedPosts from '../components/Posts/Related/RelatedPosts'
import { RenderShortcode } from '../components/RenderShortcode'
import { PrevNextNavigation } from '../components/Posts/PrevNextNavigation'
import './post.scss'
import { decodeEntities } from '../utils/helpers'
import { NewsletterSignUp } from '../components/NewsletterSignUp'

export const BlogPostTemplate = ({
  content,
  categories,
  title,
  date,
  blogSlug,
}) => {
  return (
    <section className="single-post">
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            <div className="wrap">
              <RenderShortcode content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const BlogPost = props => {
  const { data, location, pageContext } = props
  const { wordpressPost: post, site, siteSettings, wordpressWpSettings } = data
  if (!post) return null
  const { title, content, featured_media, yoast, categories, date } = post
  const { blogSlug } = wordpressWpSettings
  const { newsletterText } = siteSettings.options

  return (
    <>
      <SEO
        title={`${yoast.metaTitle || decodeEntities(title)} | ${decodeEntities(
          wordpressWpSettings.title
        )}`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <PageHeader
        headerTitle={title}
        headerSubTitle={date}
        headerBackgroundImage={featured_media}
        location={location}
      />
      <BlogPostTemplate
        content={content}
        categories={categories}
        title={title}
        date={date}
        blogSlug={blogSlug}
      />
      <PostRelatedCategories categories={categories} />
      <PrevNextNavigation pageContext={pageContext} archive="/blog/" />
      <RelatedPosts />
      <NewsletterSignUp content={newsletterText} />
    </>
  )
}

export default Previewable(BlogPost, 'post')

export const pageQuery = graphql`
  fragment PostFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "MMMM Qo gggg")
    title
  }
  query BlogPostByID($id: String!) {
    wordpressWpSettings {
      title
      wordpressUrl
      blogSlug
    }
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      excerpt
      wordpress_id
      date(formatString: "MMMM Qo gggg")
      featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 670, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      categories {
        name
        slug
        path
      }
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
        opengraph_image {
          source_url
        }
        twitter_image {
          source_url
        }
      }
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        newsletterText
      }
    }
  }
`
